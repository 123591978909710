import {BrowserModule, Title} from '@angular/platform-browser';
import {InjectionToken, LOCALE_ID, NgModule} from '@angular/core';
import {CommonModule, DatePipe, LocationStrategy, registerLocaleData} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import localeDe from '@angular/common/locales/de';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatDialogModule} from '@angular/material/dialog';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {ToastrModule} from 'ngx-toastr';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {ApiModule, BASE_PATH} from '@io-elon-common/frontend-api';
import {environment} from '../environments/environment';
import {UserListViewComponent} from './modules/users/views/user-list-view/user-list-view.component';
import {EditUserDialogComponent} from './modules/users/dialogs/edit-user-dialog/edit-user-dialog.component';
import {EditPasswordDialogComponent} from './modules/users/dialogs/edit-password/edit-password-dialog.component';
import {CreateUserDialogComponent} from './modules/users/dialogs/create-user/create-user-dialog.component';
import {IconsComponent} from './modules/debug/icons/icons.component';
import {MatIconModule} from '@angular/material/icon';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {MAT_MOMENT_DATE_FORMATS, MomentDateAdapter} from '@angular/material-moment-adapter';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {ReservationsListViewComponent} from './modules/reservations/views/reservation-list-view/reservations-list-view.component';
import {BasisDetailsViewComponent} from './modules/basis/views/basis-details-view/basis-details-view.component';
import {MatCardModule} from '@angular/material/card';
import {DongleListViewComponent} from './modules/dongle/views/dongle-list-view/dongle-list-view.component';
import {PermissionsGroupListViewComponent} from './modules/permissions/views/permissions-group-list-view/permissions-group-list-view.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CheckVersionInterceptor} from './services/api-handlers/check-version.interceptor';
import {CheckServerTimeInterceptor} from './services/api-handlers/check-server-time.interceptor';
import {PerformanceComponent} from './modules/debug/performance/performance.component';
import {MeterListViewComponent} from './modules/meter/views/meter-list-view/meter-list-view.component';
import {MeterDetailsViewComponent} from './modules/meter/views/meter-details-view/meter-details-view.component';
import {AlgoConfigsTilesViewComponent} from './modules/algo-configs/views/algo-config-tiles-view/algo-configs-tiles-view.component';
import {QueueSizeComponent} from './modules/debug/queue-size/queue-size.component';
import {AlgoDebuggerViewComponent} from './modules/charge-plan/views/algo-debugger-view/algo-debugger-view.component';
import {OcppDebuggerViewComponent} from './modules/ocpp-debugger/views/ocpp-debugger-view/ocpp-debugger-view.component';
import {WizardComponent} from './modules/ocpp-debugger/wizard/wizard.component';
import {OcppUplinksListViewComponent} from './modules/ocpp-uplinks/views/ocpp-uplinks-list-view/ocpp-uplinks-list-view.component';
import {LocalStorageComponent} from './modules/debug/local-storage/local-storage.component';
import {ServiceComponent} from './modules/debug/service/service.component';
import {TeslaAccountsListViewComponent} from './modules/tesla/views/tesla-accounts-list-view/tesla-accounts-list-view.component';
import {EntityManagerDebuggerComponent} from './modules/debug/entity-manager-debugger/entity-manager-debugger.component';
import { ApiDocComponent } from './modules/debug/api-doc/api-doc.component';
import { FreeToMoveListViewComponent } from './modules/free-to-move/views/free-to-move-list-view/free-to-move-list-view.component';
import {AgePipe} from "./shared/helper/age.pipe";
import {
    AlgoV2ConfigGraphsComponent
} from "./modules/algo-configs/components/algo-v2-config-graphs/algo-v2-config-graphs.component";
import {
    AlgoV2ConfigTableComponent
} from "./modules/algo-configs/components/algo-v2-config-table/algo-v2-config-table.component";
import {AlgoV2ConfigTileComponent} from "./modules/algo-configs/components/algo-v2-config-tile/algo-v2-config-tile.component";
import {AlgoV2ConfigValuesPipePipe} from "./shared/helper/algo-v2-config-values-pipe.pipe";
import {
    BackgroundLegendComponent
} from "./shared/components/history-graph/background-legend/background-legend.component";
import {
    BatteryIcon,
    EvseName,
    FirstName,
    FleetName,
    FullName,
    LastName,
    ReservationName,
    VehicleConnected, VehicleName,
    MeterName
} from "./shared/helper/pipes";
import {BoxesComponent} from "./shared/components/boxes/boxes.component";
import {ConfirmDialogComponent} from "./shared/components/dialogs/confirm-dialog/confirm-dialog.component";
import {DataTableComponent} from "./shared/components/tables/data-table/data-table.component";
import {DateTimeFormComponent} from "./modules/vehicle-key/components/date-time-form/date-time-form.component";
import {DefaultButtonsComponent} from "./shared/components/dialogs/default-buttons/default-buttons.component";
import {DongleTableComponent} from "./modules/dongle/components/dongle-table/dongle-table.component";
import {DragHelperDirective} from "./shared/helper/drag-helper.directive";
import {DurationPipe} from "./shared/helper/duration.pipe";
import {
    DynamicContainerDirective,
    EditDialogComponent
} from "./shared/components/dialogs/edit-dialog/edit-dialog.component";
import {EaseeLoginDialogComponent} from "./modules/evse/dialogs/easee-login-dialog/easee-login-dialog.component";
import {EditAlgoConfigDialogComponent} from "./modules/algo-configs/dialogs/edit-algo-config-dialog/edit-algo-config-dialog.component";
import {EditBasisDialogComponent} from "./modules/basis/dialogs/edit-basis-dialog/edit-basis-dialog.component";
import {EditReservationDialogComponent} from "./modules/reservations/dialogs/edit-reservation-dialog/edit-reservation-dialog.component";
import {EditDongleDialogComponent} from "./modules/dongle/dialogs/edit-dongle-dialog/edit-dongle-dialog.component";
import {EditEvseDialogComponent} from "./modules/evse/dialogs/edit-evse-dialog/edit-evse-dialog.component";
import {EditRfidDialogComponent} from "./modules/rfid/dialogs/edit-rfid-dialog/edit-rfid-dialog.component";
import {EditFleetDialogComponent} from "./modules/basis/dialogs/edit-fleet-dialog/edit-fleet-dialog.component";
import {EditLoadDialogComponent} from "./modules/loads/dialogs/edit-load-dialog/edit-load-dialog.component";
import {EditMeterDialogComponent} from "./modules/meter/dialogs/edit-meter-dialog/edit-meter-dialog.component";
import {EditOcppUplinkDialogComponent} from "./modules/ocpp-uplinks/dialogs/edit-ocpp-uplink-dialog/edit-ocpp-uplink-dialog.component";
import {EditPermissionDialogComponent} from "./modules/permissions/dialogs/edit-permission-dialog/edit-permission-dialog.component";
import {EditPowerSupplyDialogComponent, FactorPipe} from "./modules/basis/dialogs/edit-power-supply-dialog/edit-power-supply-dialog.component";
import {EditSolarSystemDialogComponent} from "./modules/solar/dialogs/edit-solar-system-dialog/edit-solar-system-dialog.component";
import {
    EditTeslaApiDatasourceDialogComponent
} from "./modules/tesla/dialogs/edit-tesla-api-dialog/edit-tesla-api-datasource-dialog.component";
import {EditVehicleDialogComponent} from "./modules/vehicle/dialogs/edit-vehicle-dialog/edit-vehicle-dialog.component";
import {EditVehicleKeyDialogComponent} from "./modules/vehicle-key/dialogs/edit-vehicle-key-dialog/edit-vehicle-key-dialog.component";
import {
    ElectricityCostsPlanComponent
} from "./modules/basis/components/electricity-costs-plan/electricity-costs-plan.component";
import {EventLogTableComponent} from "./shared/components/tables/event-log-table/event-log-table.component";
import {EvseChargingsTableComponent} from "./shared/components/tables/chargings-table/evse-chargings-table.component";
import {
    EvseHistoryGraphComponent
} from "./modules/evse/components/evse-history-graph/evse-history-graph.component";
import {EvseTableComponent} from "./modules/evse/components/evse-table/evse-table.component";
import {FooterComponent} from "./shared/components/footer/footer.component";
import {
    GiveOutVehicleKeyDialogComponent
} from "./modules/vehicle-key/dialogs/give-out-vehicle-key-dialog/give-out-vehicle-key-dialog.component";
import {
    GraphDatePickerComponent
} from "./shared/components/history-graph/graph-date-picker/graph-date-picker.component";
import {HeaderComponent} from "./shared/components/header/header.component";
import {LoadConfigTileComponent} from "./modules/loads/components/load-config-tile/load-config-tile.component";
import {LoadTableComponent} from "./modules/loads/components/load-table/load-table.component";
import {LoadTilesViewComponent} from "./modules/loads/views/load-tiles-view/load-tiles-view.component";
import {
    MeterHistoryGraphComponent
} from "./modules/meter/components/meter-history-graph/meter-history-graph.component";
import {MeterTableComponent} from "./modules/meter/components/meter-table/meter-table.component";
import {NotificationIconComponent} from "./shared/components/header/notification-icon/notification-icon.component";
import {NotificationIconPipe} from "./shared/helper/notification-icon.pipe";
import {NotificationIconStylePipe} from "./shared/helper/notification-color.pipe";
import {
    NotificationMenuLineComponent
} from "./shared/components/header/notification-menu-line/notification-menu-line.component";
import {OcppUplinksTableComponent} from "./modules/ocpp-uplinks/components/ocpp-uplinks-table/ocpp-uplinks-table.component";
import {
    PeakHistoryGraphComponent
} from "./modules/basis/components/peak-history-graph/peak-history-graph.component";
import {PermissionTableComponent} from "./modules/permissions/components/permission-table/permission-table.component";
import { PermissionGroupTableComponent } from './modules/permissions/components/permission-group-table/permission-group-table.component';
import {RadialBarComponent} from "./shared/components/radial-bar/radial-bar.component";
import {RelativeTimeComponent} from "./modules/vehicle-key/components/vehicle-key-table/relative-time/relative-time.component";
import {SidebarComponent} from "./shared/components/sidebar/sidebar.component";
import {SimpleGraphComponent} from "./modules/loads/components/simple-graph/simple-graph.component";
import {
    SingleBackgroundLegendComponent
} from "./shared/components/history-graph/background-legend/single-background-legend/single-background-legend.component";
import {
    SohHistoryGraphComponent
} from "./modules/vehicle/components/soh-history-graph/soh-history-graph.component";
import {TimeRangePickerComponent} from "./modules/reservations/components/time-range-picker/time-range-picker.component";
import {
    U12HistoryGraphComponent
} from "./modules/vehicle/components/u12-history-graph/u12-history-graph.component";
import {
    UserDefinedLoadGraphComponent
} from "./modules/loads/components/user-defined-load-graph/user-defined-load-graph.component";
import {UserTableComponent} from "./modules/users/components/user-table/user-table.component";
import {
    VehicleChargingsTableComponent
} from "./shared/components/tables/chargings-table/vehicle-chargings-table.component";
import {
    VehicleHistoryGraphComponent
} from "./modules/vehicle/components/vehicle-history-graph/vehicle-history-graph.component";
import {VehicleKeyTableComponent} from "./modules/vehicle-key/components/vehicle-key-table/vehicle-key-table.component";
import {VehicleLinkComponent} from "./shared/components/links/vehicle-link/vehicle-link.component";
import {
    VehicleReservationGraphComponent
} from "./modules/reservations/components/vehicle-reservation-graph/vehicle-reservation-graph.component";
import {VehicleTableComponent} from "./modules/vehicle/components/vehicle-table/vehicle-table.component";
import {RfidTableComponent} from "./modules/rfid/components/rfid-table/rfid-table.component";
import {ZoomHelperDirective} from "./shared/helper/zoom-helper.directive";
import {EditTeslaAccountDialogComponent} from "./modules/tesla/dialogs/edit-tesla-account-dialog/edit-tesla-account-dialog.component";
import {TeslaAccountTableComponent} from "./modules/tesla/components/tesla-account-table/tesla-account-table.component";
import {
    PowerSupplyTreeViewComponent
} from "./modules/basis/components/power-supply-tree-view/power-supply-tree-view.component";
import {
    PowerSupplyTreeViewNodeComponent
} from "./modules/basis/components/power-supply-tree-view/power-supply-tree-view-node/power-supply-tree-view-node.component";
import {
    EvseTreeViewNodeComponent
} from "./modules/basis/components/power-supply-tree-view/evse-tree-view-node/evse-tree-view-node.component";
import {
    SolarSystemTreeViewNodeComponent
} from "./modules/basis/components/power-supply-tree-view/solar-panel-tree-view-node/solar-system-tree-view-node.component";
import {
    ElectricityBarComponent
} from "./modules/basis/components/power-supply-tree-view/electricity-bar/electricity-bar.component";
import {ActorArgComponent} from "./modules/evse/dialogs/edit-evse-dialog/actor-arg/actor-arg.component";
import {EvseLinkComponent} from "./shared/components/links/evse-link/evse-link.component";
import {
    DetailsDialogComponent
} from "./shared/components/tables/chargings-table/details-dialog/details-dialog.component";
import {ChargePlanNewComponent} from "./modules/charge-plan/components/charge-plan-new/charge-plan-new.component";
import {EditFreeToMoveDialogComponent} from "./modules/free-to-move/dialogs/edit-free-to-move/edit-free-to-move-dialog.component";
import {FreeToMoveTableComponent} from "./modules/free-to-move/components/free-to-move-table/free-to-move-table.component";
import {NewBasisDialogComponent} from "./modules/basis/dialogs/new-basis-dialog/new-basis-dialog.component";
import {EditCustomerComponent} from "./shared/components/dialogs/edit-customer/edit-customer.component";
import {NewCustomerComponent} from "./shared/components/dialogs/new-customer/new-customer.component";
import {NgCircleProgressModule} from "ng-circle-progress";
import {RouterModule} from "@angular/router";
import {MatTreeModule} from "@angular/material/tree";
import {MatProgressBarModule} from "@angular/material/progress-bar";
import {FormatterFactoryService} from "./shared/components/tables/data-table/formatter-factory.service";
import {TitleService} from "./shared/components/header/title.service";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatSidenavModule} from "@angular/material/sidenav";
import {MatBadgeModule} from "@angular/material/badge";
import {MatListModule} from "@angular/material/list";
import {MatGridListModule} from "@angular/material/grid-list";
import {MatRadioModule} from "@angular/material/radio";
import {MatChipsModule} from "@angular/material/chips";
import {MatTooltipModule} from "@angular/material/tooltip";
import {MatTableModule} from "@angular/material/table";
import {MatPaginatorModule} from "@angular/material/paginator";
import {MatDividerModule} from "@angular/material/divider";
import {MatSortModule} from "@angular/material/sort";
import {MatSnackBarModule} from "@angular/material/snack-bar";
import {MatSliderModule} from "@angular/material/slider";
import {MatMenuModule} from "@angular/material/menu";
import {MatTabsModule} from "@angular/material/tabs";
import {MatSlideToggleModule} from "@angular/material/slide-toggle";
import {LoginComponent} from "./layout/login/login.component";
import {PasswordResetComponent} from "./layout/password-reset/password-reset.component";
import {AuthService} from "./shared/guards/auth.service";
import {AuthGuard} from "./shared/guards/auth.guard";
import {DefaultComponent} from "./layout/default/default.component";
import {UserDetailsViewComponent} from "./modules/users/views/user-details-view/user-details-view.component";
import {SettingsViewComponent} from "./modules/settings/views/settings-view.component";
import {SearchViewComponent} from "./modules/search/views/search-view.component";
import {EvseListViewComponent} from "./modules/evse/views/evse-list-view/evse-list-view.component";
import {EvseDetailsViewComponent} from "./modules/evse/views/evse-detail-view/evse-details-view.component";
import {VehicleListViewComponent} from "./modules/vehicle/views/vehicle-list-view/vehicle-list-view.component";
import {HelpViewComponent} from "./modules/help/views/help-view/help-view.component";
import {RfidListViewComponent} from "./modules/rfid/views/rfid-list-view/rfid-list-view.component";
import { ActorDetailsDialogComponent } from './modules/evse/dialogs/actor-details-dialog/actor-details-dialog.component';
import {
    LoadTreeViewNodeComponent
} from './modules/basis/components/power-supply-tree-view/load-tree-view-node/load-tree-view-node.component';
import { MeterConnectionDebugViewComponent } from './modules/meter/views/meter-connection-debug-view/meter-connection-debug-view.component';
import { MeterLiveDataDebugViewComponent } from './modules/meter/views/meter-live-data-debug-view/meter-live-data-debug-view.component';
import { EditFormulaComponent } from './modules/loads/dialogs/edit-load-dialog/edit-formula/edit-formula.component';
import { LoadHistoryDialogComponent } from './modules/loads/dialogs/load-history-dialog/load-history-dialog.component';
import { LoadLiveDataViewComponent } from './modules/loads/views/load-live-data/load-live-data-view.component';
import {OpenAdrVenEntityTableComponent} from "./modules/open-adr-ven-entity/components/open-adr-ven-entity-table/open-adr-ven-entity-table.component";
import { OpenAdrVenListViewComponent } from './modules/open-adr-ven-entity/views/open-adr-ven-list-view/open-adr-ven-list-view.component';
import { EditOpenAdrVenDialogComponent } from './modules/open-adr-ven-entity/dialogs/edit-open-adr-ven-dialog/edit-open-adr-ven-dialog.component';
import { EditPermissionGroupDialogComponent } from './modules/permissions/dialogs/edit-permission-group-dialog/edit-permission-group-dialog.component';
import {PreserveQueryLocationStrategy} from './utils/preserveQueryLocationStrategy';
import { ChargingSessionSearchViewComponent } from './modules/charging-session/views/charging-session-search-view/charging-session-search-view.component';
import { ChargingSessionTableComponent } from "./shared/components/tables/chargings-table/charging-session-table";
import { LiveDataComponent } from './modules/debug/live-data/live-data.component';
import { EvseActionsDialogComponent } from './modules/evse/dialogs/evse-actions-dialog/evse-actions-dialog.component';
import { NewUserTableComponent } from './modules/users/components/new-user-table/new-user-table.component';
import { TronityLandingComponent } from './layout/tronity-landing/tronity-landing.component';
import { ModbusConnectionsComponent } from './modules/debug/modbus-connections/modbus-connections.component';
import { EditWhitelistEntryDialogComponent } from './modules/whitelist/dialogs/edit-whitelist-entry-dialog/edit-whitelist-entry-dialog.component';
import { EvseValidationHandlingDialogComponent } from './modules/evse/dialogs/evse-validation-handling-dialog/evse-validation-handling-dialog.component';
import { WhitelistViewComponent } from './modules/whitelist/view/whitelist-view/whitelist-view.component';
import { SmartmeterDataV1DialogComponent } from './modules/meter/dialogs/smartmeter-data-v1-dialog/smartmeter-data-v1-dialog.component';
import { ConfigurationListViewComponent } from './modules/configuration/views/configuration-list-view/configuration-list-view.component';
import { EditConfigurationCommentDialogComponent } from './modules/configuration/dialogs/edit-configuration-comment-dialog/edit-configuration-comment-dialog.component';
import { EvseLogDialogComponent } from './modules/evse/dialogs/evse-log-dialog/evse-log-dialog.component';
import { SolarTilesView } from './modules/solar/views/solar-tiles-view/solar-tiles-view.component';
import { EditSolarPanelDialogComponent } from './modules/solar/dialogs/edit-solar-panel-dialog/edit-solar-panel-dialog.component';
import { ChargePlanViewComponent } from './modules/charge-plan/views/charge-plan-view/charge-plan-view.component';
import { GraphHelpIconComponent } from './shared/components/graph-help-icon/graph-help-icon.component';
import { SolarHistoryGraphComponent } from './modules/solar/components/solar-history-graph/solar-history-graph.component';
import { RfidUploadDialogComponent } from './modules/rfid/dialogs/rfid-upload-dialog/rfid-upload-dialog.component';
import { UnknownCpisListViewComponent } from './modules/unknown-cpis/views/unknown-cpis-view/unknown-cpis-list-view.component';
import { UnknownCpiTableComponent } from './modules/unknown-cpis/components/unknown-cpi-table/unknown-cpi-table.component';
import { DetailsUnknownCpiDialogComponent } from './modules/unknown-cpis/dialogs/details-unknown-cpi/details-unknown-cpi-dialog.component';
import { DbPerformanceComponent } from './modules/debug/db-performance/db-performance.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import { MeterNoFleetInfoComponent } from './shared/components/meter-no-fleet-info/meter-no-fleet-info.component';
import { MeterNoFleetTableComponent } from './modules/meter/components/meter-no-fleet-table/meter-no-fleet-table.component';
import { SolarSystemNoFleetInfoComponent } from './shared/components/solar-system-no-fleet-info/solar-system-no-fleet-info.component';
import { SolarSystemNoFleetTableComponent } from './modules/solar/components/solar-system-no-fleet-table/solar-system-no-fleet-table.component';
import { MeterLinkComponent } from './shared/components/links/meter-link/meter-link.component';
import { NotificationComponent } from './shared/components/notification/notification.component';
import { EvseBulkOperationsViewComponent } from './modules/evse/views/evse-bulk-operations-view/evse-bulk-operations-view.component';
import {MatStepperModule} from "@angular/material/stepper";
import { STecAccountsListViewComponent } from './modules/s-tec-accounts/views/s-tec-accounts-list-view/s-tec-accounts-list-view.component';
import { EditSTecAccountDialogComponent } from './modules/s-tec-accounts/dialogs/edit-s-tec-account-dialog/edit-s-tec-account-dialog.component';
import { STecAccountTableComponent } from './modules/s-tec-accounts/components/s-tec-account-table/s-tec-account-table.component';
import { EditDsSTecComponent } from './modules/vehicle/dialogs/edit-vehicle-dialog/edit-ds-s-tec/edit-ds-s-tec.component';
import { IconComponent } from './shared/components/icon/icon.component';
import { BreadcrumbComponent } from './shared/components/header/breadcrumb/breadcrumb.component';
import {BehaviorSubject} from "rxjs";
import {
    VehicleActionDialogComponent
} from "./modules/vehicle/dialogs/vehicle-action-dialog/vehicle-action-dialog.component";
import {VehicleDetailsViewComponent} from "./modules/vehicle/views/vehicle-details-view/vehicle-details-view.component";
import {
    VehicleKeyListViewComponent
} from "./modules/vehicle-key/views/vehicle-key-list-view/vehicle-key-list-view.component";
import { ExportComponent } from "./modules/debug/export/export.component";
import { CodeDirective } from "./shared/components/code/code.directive";
import { TimeZonePickerComponent } from './modules/reservations/components/time-range-picker/time-zone-picker/time-zone-picker.component';
import {
    EvseHealthStatusTableComponent
} from './modules/evse/tables/evse-health-status-table/evse-health-status-table.component';
import {
    EvseHealthHistoryViewComponent
} from './modules/evse-health/evse-fault-history-overview/evse-health-history-view/evse-health-history-view.component';
import {
    EvseHealthHistoryTableComponent
} from './modules/evse-health/tables/evse-health-history-table/evse-health-history-table.component';
import {EvseHealthDiagrammComponent} from './modules/evse-health/evse-health-diagram-list/evse-health-diagramm/evse-health-diagramm.component';
import {
    EvseHealthDiagramListComponent
} from './modules/evse-health/evse-health-diagram-list/evse-health-diagram-list.component';
import {FleetFaultStatisticsComponent} from './modules/evse-health/evse-fault-history-overview/fleet-fault-statistics/fleet-fault-statistics.component';
import {DateSelectionComponent} from './modules/evse-health/date-selection/date-selection.component';
import {CdkConnectedOverlay, CdkOverlayOrigin} from '@angular/cdk/overlay';
import {BasisTabGroupComponent} from './modules/basis/views/basis-tab-group/basis-tab-group.component';
import {EvseActionsDropdownComponent} from './modules/evse/views/evse-actions-dropdown/evse-actions-dropdown.component';
import {
    EvseLogUploadsDialogComponent
} from "./modules/evse/dialogs/evse-log-uploads-dialog/evse-log-uploads-dialog.component";
import {TeslaLandingComponent} from './layout/tesla-landing/tesla-landing.component';
import {ValidationErrorFormatterPipe} from "./shared/components/validation-error/validation-error-formatter.pipe";
import {HelpBoxComponentDialog} from './shared/components/help-box/help-box-dialog/help-box-component-dialog.component';
import {HelpBoxIconComponent} from './shared/components/help-box/help-box-icon/help-box-icon.component';
import {
    LoadFormulaSimulationComponent
} from "./modules/loads/views/load-formula-simulation/load-formula-simulation.component";
import {
    LoadFormulaSimulationResultComponent
} from "./modules/loads/views/load-formula-simulation/load-formula-simulation-result/load-formula-simulation-result.component";
import {AlgoReasonComponent} from "./shared/components/algo-reason/algo-reason.component";
import {
    SetSohVehicleDialogComponent
} from './modules/vehicle/dialogs/set-soh-vehicle-dialog/set-soh-vehicle-dialog.component';
import {EditNoteComponent} from './modules/note/views/edit-note/edit-note.component';
import {NoteTableComponent} from './modules/note/views/note-table/note-table.component';
import {RfidDetailsViewComponent} from './modules/rfid/views/rfid-details-view/rfid-details-view.component';
import {
    DongleDetailsViewComponent
} from './modules/dongle/components/dongle-details-view/dongle-details-view.component';
import {ExternalServiceWarningComponent} from "./layout/external-service-warning/external-service-warning.component";

export const LOGIN_STATE = new InjectionToken<BehaviorSubject<boolean>>("Login state");
export const POLL_INTERVALS = {
    auth: 15_000,
    //mostly debugging requests
    system: 1_000,
    notifications: 2_000,
    //not used
    charging_sessions: 10_000,
    algo_v2_configs: 4_000,
    basis: 2_000,
    chargePlan: 5_000,
    configurationData: 10_000,
    customer: 10_000,
    dongle: 4_000,
    //High because notifications and stuff use it a lot, but low for evse details view
    evse: 2_500,
    fleet: 2_000,
    free2Move: 1_000,
    loads: 1_500,
    meter: 1_500,
    migrations: 15000, //Low because it happens lots of time in the background
    uplinks: 1_500,
    openAdr: 1_500,
    //Not used
    permissionGroups: 1_500,
    //Not used
    permissions: 1_500,
    //Not used
    powerSupplies: 1_500,
    //Not used
    reservation: 1_500,
    rfid: 1_500,
    s_tec: 1_500,
    //Not used
    solarPanel: 1_500,
    solarSystem: 1_500,
    teslaAccounts: 1_500,
    teslaDatasource: 1_500,
    unknown_ocpp: 5_000,
    user: 1_500,
    vehicleKeys: 1_500,
    vehicle: 2_500,
    whiteliste: 1_500
};


registerLocaleData(localeDe, 'de-DE');

@NgModule({
    declarations: [
        ActorArgComponent,
        AgePipe,
        AlgoConfigsTilesViewComponent,
        AlgoDebuggerViewComponent,
        AlgoV2ConfigGraphsComponent,
        AlgoV2ConfigTableComponent,
        AlgoV2ConfigTileComponent,
        AlgoV2ConfigValuesPipePipe,
        ApiDocComponent,
        AppComponent,
        BackgroundLegendComponent,
        BasisDetailsViewComponent,
        BatteryIcon,
        BoxesComponent,
        ChargePlanNewComponent,
        ConfirmDialogComponent,
        CreateUserDialogComponent,
        DataTableComponent,
        DateTimeFormComponent,
        DefaultButtonsComponent,
        DefaultComponent,
        DetailsDialogComponent,
        DongleListViewComponent,
        DongleTableComponent,
        DragHelperDirective,
        DurationPipe,
        DynamicContainerDirective,
        EaseeLoginDialogComponent,
        EditAlgoConfigDialogComponent,
        EditBasisDialogComponent,
        EditReservationDialogComponent,
        EditCustomerComponent,
        EditDialogComponent,
        EditDongleDialogComponent,
        EditEvseDialogComponent,
        EditFleetDialogComponent,
        EditFreeToMoveDialogComponent,
        EditLoadDialogComponent,
        EditMeterDialogComponent,
        EditOcppUplinkDialogComponent,
        EditPasswordDialogComponent,
        EditPermissionDialogComponent,
        EditPermissionGroupDialogComponent,
        EditPowerSupplyDialogComponent,
        EditRfidDialogComponent,
        EditSolarSystemDialogComponent,
        EditTeslaAccountDialogComponent,
        EditTeslaApiDatasourceDialogComponent,
        EditUserDialogComponent,
        EditVehicleDialogComponent,
        EditVehicleKeyDialogComponent,
        ElectricityCostsPlanComponent,
        EntityManagerDebuggerComponent,
        ExportComponent,
        EventLogTableComponent,
        EvseHealthStatusTableComponent,
        EvseChargingsTableComponent,
        EvseDetailsViewComponent,
        EvseHistoryGraphComponent,
        EvseLinkComponent,
        EvseLogUploadsDialogComponent,
        EvseName,
        EvseListViewComponent,
        EvseTableComponent,
        EvseTreeViewNodeComponent,
        FirstName,
        VehicleListViewComponent,
        FleetName,
        FooterComponent,
        FreeToMoveListViewComponent,
        FreeToMoveTableComponent,
        FullName,
        GiveOutVehicleKeyDialogComponent,
        GraphDatePickerComponent,
        HeaderComponent,
        HelpViewComponent,
        IconsComponent,
        LastName,
        LoadConfigTileComponent,
        LoadFormulaSimulationComponent,
        LoadTilesViewComponent,
        LoadTableComponent,
        LocalStorageComponent,
        LoginComponent,
        PasswordResetComponent,
        MeterDetailsViewComponent,
        MeterHistoryGraphComponent,
        MeterListViewComponent,
        MeterTableComponent,
        NewBasisDialogComponent,
        NewCustomerComponent,
        NewUserTableComponent,
        NotificationIconComponent,
        NotificationIconPipe,
        NotificationIconStylePipe,
        NotificationMenuLineComponent,
        OcppDebuggerViewComponent,
        OcppUplinksListViewComponent,
        OcppUplinksTableComponent,
        PeakHistoryGraphComponent,
        PerformanceComponent,
        PermissionsGroupListViewComponent,
        PermissionTableComponent,
        ExternalServiceWarningComponent,
        PermissionGroupTableComponent,
        PowerSupplyTreeViewComponent,
        PowerSupplyTreeViewNodeComponent,
        LoadTreeViewNodeComponent,
        ElectricityBarComponent,
        QueueSizeComponent,
        RadialBarComponent,
        RelativeTimeComponent,
        ReservationName,
        ReservationsListViewComponent,
        RfidListViewComponent,
        RfidTableComponent,
        SearchViewComponent,
        ServiceComponent,
        SettingsViewComponent,
        SetSohVehicleDialogComponent,
        SidebarComponent,
        SimpleGraphComponent,
        SingleBackgroundLegendComponent,
        SohHistoryGraphComponent,
        SolarSystemTreeViewNodeComponent,
        TeslaAccountsListViewComponent,
        TeslaAccountTableComponent,
        TimeRangePickerComponent,
        U12HistoryGraphComponent,
        UserDetailsViewComponent,
        UserDefinedLoadGraphComponent,
        UserListViewComponent,
        UserTableComponent,
        VehicleActionDialogComponent,
        VehicleChargingsTableComponent,
        VehicleDetailsViewComponent,
        VehicleConnected,
        VehicleHistoryGraphComponent,
        VehicleKeyListViewComponent,
        VehicleKeyTableComponent,
        VehicleLinkComponent,
        VehicleName,
        MeterName,
        VehicleReservationGraphComponent,
        VehicleTableComponent,
        WizardComponent,
        ZoomHelperDirective,
        ActorDetailsDialogComponent,
        FactorPipe,
        MeterConnectionDebugViewComponent,
        MeterLiveDataDebugViewComponent,
        EditFormulaComponent,
        LoadHistoryDialogComponent,
        LoadLiveDataViewComponent,
        OpenAdrVenEntityTableComponent,
        OpenAdrVenListViewComponent,
        EditOpenAdrVenDialogComponent,
        ChargingSessionSearchViewComponent,
        ChargingSessionTableComponent,
        LiveDataComponent,
        EvseActionsDialogComponent,
        TronityLandingComponent,
        TeslaLandingComponent,
        ModbusConnectionsComponent,
        EditWhitelistEntryDialogComponent,
        EvseValidationHandlingDialogComponent,
        HelpBoxComponentDialog,
        HelpBoxIconComponent,
        WhitelistViewComponent,
        ModbusConnectionsComponent,
        SmartmeterDataV1DialogComponent,
        ConfigurationListViewComponent,
        EditConfigurationCommentDialogComponent,
        EvseLogDialogComponent,
        SolarTilesView,
        EditSolarPanelDialogComponent,
        ChargePlanViewComponent,
        SolarHistoryGraphComponent,
        RfidUploadDialogComponent,
        GraphHelpIconComponent,
        UnknownCpisListViewComponent,
        UnknownCpiTableComponent,
        DetailsUnknownCpiDialogComponent,
        DbPerformanceComponent,
        MeterNoFleetInfoComponent,
        MeterNoFleetTableComponent,
        SolarSystemNoFleetInfoComponent,
        SolarSystemNoFleetTableComponent,
        MeterLinkComponent,
        NotificationComponent,
        EvseBulkOperationsViewComponent,
        STecAccountsListViewComponent,
        EditSTecAccountDialogComponent,
        STecAccountTableComponent,
        EditDsSTecComponent,
        IconComponent,
        BreadcrumbComponent,
        EvseHealthHistoryViewComponent,
        EvseHealthHistoryTableComponent,
        EvseHealthDiagrammComponent,
        EvseHealthDiagramListComponent,
        FleetFaultStatisticsComponent,
        BreadcrumbComponent,
        TimeZonePickerComponent,
        DateSelectionComponent,
        BasisTabGroupComponent,
        EvseActionsDropdownComponent,
        EditNoteComponent,
        NoteTableComponent,
        RfidDetailsViewComponent,
        DongleDetailsViewComponent
    ],
    imports: [
        /*AgmCoreModule.forRoot({apiKey: 'AIzaSyDvpbOUP55U1B8XeIx_MAn2DH95h429WYk'}),*/
        ApiModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        CommonModule,
        FormsModule,
        HttpClientModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatButtonModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        NgCircleProgressModule.forRoot({
            // set defaults here
            radius: 100,
            outerStrokeWidth: 16,
            innerStrokeWidth: 8,
            outerStrokeColor: '#78C000',
            innerStrokeColor: '#C7E596',
            animationDuration: 300,
        }),
        NgxMaterialTimepickerModule.setOpts('de-DE', 'latn'),
        ReactiveFormsModule,
        RouterModule,
        ToastrModule.forRoot(),
        MatButtonToggleModule,
        CdkOverlayOrigin,
        CdkConnectedOverlay,
        MatButtonToggleModule,
        CodeDirective,
        MatButtonToggleModule,
        CdkConnectedOverlay,
        CdkOverlayOrigin,
        ValidationErrorFormatterPipe,
        LoadFormulaSimulationResultComponent,
        AlgoReasonComponent,
    ],
    providers: [
        AgePipe,
        AuthGuard,
        AuthService,
        DatePipe,
        DurationPipe,
        EvseName,
        FleetName,
        FormatterFactoryService,
        MatDatepickerModule,
        MatNativeDateModule,
        ReservationName,
        Title,
        TitleService,
        VehicleName,
        {provide: BASE_PATH, useValue: environment.API_BASE_PATH},
        {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
        {provide: HTTP_INTERCEPTORS, useClass: CheckVersionInterceptor, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: CheckServerTimeInterceptor, multi: true},
        {provide: LocationStrategy, useClass: PreserveQueryLocationStrategy},
        {provide: LOCALE_ID, useValue: 'de-DE'},
        {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
        {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
        {provide: LOGIN_STATE, useValue: new BehaviorSubject<boolean>(false)}
    ],
    exports: [
        HelpBoxComponentDialog
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
